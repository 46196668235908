const dev = {
  s3: {
    BUCKET: "",
  },
  api: {
    PROTOCOL: "https",
    HOSTNAME: process.env.REACT_APP_API_HOSTNAME ?? "percipium.cloud",
    PORT: 443,
    PATH: `${process.env.REACT_APP_API_PATH ?? "/api/v1"}`,
  },
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "4chBbsPNJ",
    APP_CLIENT_ID: "1k3lu8fovcus5p26pqm3pap2b5",
    CALLBACK_URL: `http://localhost:3000`,
    DOMAIN: "combase-percipium",
  },
};

const prod = {
  s3: {
    BUCKET: "",
  },
  api: {
    PROTOCOL: "https",
    HOSTNAME: process.env.REACT_APP_API_HOSTNAME ?? "percipium.cloud",
    PORT: 443,
    PATH: `${process.env.REACT_APP_API_PATH ?? "/api/v1"}`,
  },
  cognito: {
    REGION: process.env.REACT_APP_COGNITO_REGION ?? "ap-southeast-2",
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? "4chBbsPNJ",
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID ?? "1k3lu8fovcus5p26pqm3pap2b5",
    CALLBACK_URL: `https://${process.env.REACT_APP_API_HOSTNAME ?? "percipium.cloud"}`,
    DOMAIN: process.env.REACT_APP_COGNITO_DOMAIN ?? "combase-percipium",
  },
};

const env = process.env.NODE_ENV === "production" ? prod : dev;

const config = {
  LOGIN_PATH: `/login`,
  ...env,
};

export default config;
