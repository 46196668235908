import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import config from "./config";

Amplify.configure({
  Auth: {
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: `${config.cognito.REGION}_${config.cognito.USER_POOL_ID}`,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: `${config.cognito.DOMAIN}.auth.${config.cognito.REGION}.amazoncognito.com`,
      scope: ["email", "openid"],
      redirectSignIn: `${config.cognito.CALLBACK_URL}`,
      redirectSignOut: `${config.cognito.CALLBACK_URL}/signout`,
      responseType: "token", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
