import React from "react";
import logo_black from "../../../assets/Percipium-Circle-icon-RGB-Black.png";
import logo_white from "../../../assets/Percipium-Circle-icon-RGB-white.png";
import { useEasierTheme } from "3asier-theme";

const LogoCircle = (
  props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & { invert?: boolean }
) => {
  const { invert, ...imgProps } = props;

  const theme = useEasierTheme();

  return (
    <img
      src={theme?.mode === "dark" ? (!invert ? logo_white : logo_black) : !invert ? logo_black : logo_white}
      {...imgProps}
    />
  );
};

export default LogoCircle;
