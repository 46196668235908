import { useEasierTheme } from "3asier-theme";
import { Box } from "@mui/material";
import { Chart } from "chart.js/auto";
import React, { useEffect } from "react";
import AnalyticsService from "services/analytics-service";

const ChartEventsByCountry = (props: { site_id: string; startDay: number | undefined; endDay: number | undefined }) => {
  const theme = useEasierTheme();
  const [id] = React.useState(`${Math.random()}`);

  const [data, setData] = React.useState<{ count: number; country_name: string | null }[]>();

  console.log(data);

  useEffect(() => {
    if (props.startDay === undefined || props.endDay === undefined) return;

    new AnalyticsService().count
      .countriesByUniqueIP(
        props.site_id,
        new Date(props.startDay ?? 0).getTime(),
        new Date(props.endDay ?? Date.now()).getTime()
      )
      .then((resp) => {
        setData(resp?.sort((a, b) => (a.count > b.count ? -1 : 1)));
      });
  }, [props.site_id, props.startDay, props.endDay]);

  useEffect(() => {
    const canvas = document.getElementById(id) as HTMLCanvasElement | undefined;

    if (!canvas) return;

    if (!data) return;

    const chart = new Chart(canvas, {
      type: "bar",
      data: {
        labels: data.map((b) => b.country_name ?? "Unknown"),
        datasets: [
          {
            borderColor: theme?.Colour.Outline(),
            backgroundColor: `${theme?.Colour.Secondary()}30`,
            label: "Country",
            data: data.map((b) => b.count),
            borderWidth: 1,
          },
        ],
      },
      options: {
        font: { family: "Roboto Flex" },
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <Box width={"100%"} height={"100%"} overflow={"hidden"}>
      <canvas id={id} style={{ width: "100%", height: "100%" }} />
    </Box>
  );
};

export default ChartEventsByCountry;
