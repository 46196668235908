import { Box, BoxProps, Divider, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect } from "react";
import DateField from "../date-field";

const DateFilter = (props: BoxProps & { onChangeDate: (val: { startTime: number; endTime: number }) => void }) => {
  const { ...boxProps } = props;

  const [selectedFilter, setSelectedFilter] = React.useState("today");

  const [fromDate, setFromDate] = React.useState(new Date(new Date().toDateString()).getTime());
  const [toDate, setToDate] = React.useState(Date.now());

  useEffect(() => {
    props.onChangeDate({ startTime: fromDate, endTime: toDate });
  }, [fromDate, toDate]);

  useEffect(() => {
    switch (selectedFilter) {
      case "today": {
        setFromDate(new Date(new Date().toDateString()).getTime());
        setToDate(Date.now());
        break;
      }
      case "7days": {
        setFromDate(Date.now() - 60_000 * 60 * 24 * 7);
        setToDate(Date.now());
        break;
      }
      case "1month": {
        const d = new Date();
        d.setMonth(d.getMonth() - 1);
        setFromDate(d.getTime());
        setToDate(Date.now());
        break;
      }
      case "6months": {
        const d = new Date();
        d.setMonth(d.getMonth() - 6);
        setFromDate(d.getTime());
        setToDate(Date.now());
        break;
      }
      case "ytd": {
        setFromDate(new Date(`${new Date().getFullYear()}`).getTime());
        setToDate(Date.now());
        break;
      }
      case "1year": {
        const d = new Date();
        d.setFullYear(d.getFullYear() - 1);
        setFromDate(d.getTime());
        setToDate(Date.now());
        break;
      }
      case "max": {
        setFromDate(0);
        setToDate(Date.now());
        break;
      }
    }
  }, [selectedFilter]);

  const timeToDate = (epoch: number) =>
    `${new Date(epoch).getFullYear()}/${(new Date(epoch).getMonth() + 1).toString().padStart(2, "0")}/${new Date(epoch)
      .getDate()
      .toString()
      .padStart(2, "0")}`;

  return (
    <Box {...boxProps}>
      <Typography variant={"h5"}>Date filter:</Typography>
      <Box marginTop={"24px"} display={"flex"}>
        <Select
          style={{ width: "300px" }}
          value={selectedFilter}
          onChange={(e) => setSelectedFilter(e.target.value as string)}
        >
          <MenuItem value={"today"}>Today</MenuItem>
          <MenuItem value={"7days"}>Last 7 days</MenuItem>
          <MenuItem value={"1month"}>1 Month</MenuItem>
          <MenuItem value={"6months"}>6 Months</MenuItem>
          <MenuItem value={"ytd"}>Year to date</MenuItem>
          <MenuItem value={"1year"}>1 Year</MenuItem>
          <MenuItem value={"max"}>Max</MenuItem>
          <Divider />
          <MenuItem value={"custom"}>Custom</MenuItem>
        </Select>
        {selectedFilter === "custom" && (
          <Box marginLeft={"32px"}>
            <DateField
              label={"From date"}
              value={timeToDate(fromDate)}
              onChange={(date) => setFromDate(new Date(date).getTime())}
            />
            <DateField
              label={"To date"}
              style={{ marginLeft: "16px" }}
              value={timeToDate(toDate)}
              onChange={(date) => setToDate(new Date(date).getTime())}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DateFilter;
