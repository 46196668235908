import React, { useEffect } from "react";
import AnalyticsService from "services/analytics-service";
import DashboardCard from "../DashboardCard";
import { Typography } from "@mui/material";

const CardTotalDownloads = (props: { site_id: string; startTime?: number; endTime?: number }) => {
  const [count, setCount] = React.useState(0);

  useEffect(() => {
    if (props.startTime === undefined || props.endTime === undefined) return;

    new AnalyticsService().events.count(props.site_id, props.startTime, props.endTime, "download").then((resp) => {
      console.log(count);
      setCount(resp?.[0].count ?? 0);
    });
  }, [props.site_id, props.startTime, props.endTime]);

  return (
    <DashboardCard>
      <Typography variant={"h5"} textAlign={"center"}>
        Total Downloads
      </Typography>
      <Typography variant={"h4"}>{count}</Typography>
    </DashboardCard>
  );
};

export default CardTotalDownloads;
