import React, { ClassAttributes } from "react";

const MaterialIcon = React.forwardRef(
  (
    props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & {
      icon?: string;
      variant?: "normal" | "outlined";
    },
    ref: ClassAttributes<HTMLSpanElement>["ref"]
  ) => {
    const getClassName = () => (props.variant === "normal" ? "material-symbols" : "material-symbols-outlined");

    return (
      <span {...props} ref={ref} className={`${getClassName()} ${props.className}`}>
        {props.icon}
      </span>
    );
  }
);

export default MaterialIcon;
