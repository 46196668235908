import React from "react";
import ContentArea from "components/common/content-area";
import LogoLarge from "components/common/logo/LogoLarge";
import { Box, Typography } from "@mui/material";
import { useEasierTheme } from "3asier-theme";

import dashboard_image from "./dashboard.png";
import visits_image from "./visits.png";
import events_image from "./events.png";
import NavCard from "./nav-card";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const theme = useEasierTheme();

  const navigate = useNavigate();

  return (
    <ContentArea display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <LogoLarge style={{ height: "256px", maxWidth: "80%", objectFit: "contain" }} />
      <Typography
        style={{
          color: theme?.Colour.OnSurface(),
          maxWidth: "840px",
          textAlign: "center",
          fontSize: "1.375rem",
          lineHeight: "1.75rem",
          fontWeight: 400,
          letterSpacing: "0rem",
        }}
      >
        Empowering businesses to conquer digital challenges. Percipium stands as the gateway to ensuring online success
        through real-time insights and web analytics.
      </Typography>
      <Box
        width={"100%"}
        maxWidth={"1264px"}
        display={"grid"}
        marginTop={"64px"}
        gridTemplateColumns={`repeat(auto-fill, minmax(400px, 1fr))`}
        gap={"8px"}
        padding={"8px"}
      >
        <NavCard
          title={"Dashboard"}
          image={dashboard_image}
          onClick={() => {
            navigate("/dashboard");
          }}
        />
        <NavCard
          title={"Visits"}
          image={visits_image}
          onClick={() => {
            navigate("/visits");
          }}
        />
        <NavCard
          title={"Events"}
          image={events_image}
          onClick={() => {
            navigate("/events");
          }}
        />
      </Box>
      <Box marginTop={"96px"}></Box>
    </ContentArea>
  );
};

export default HomePage;
