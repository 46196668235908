import ContentArea from "components/common/content-area";
import logo from "../../assets/Percipium Logo Black RGB.png";

const VisitsPage = () => {
  return (
    <ContentArea>
      <img src={logo} alt="logo" style={{ height: "128px" }} />
      <p>
        Edit <code>src/App.tsx</code> and save to reload.
      </p>
      <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
        Learn React
      </a>
    </ContentArea>
  );
};

export default VisitsPage;
