import { useEasierTheme } from "3asier-theme";
import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import ContentArea from "components/common/content-area";
import HeaderBar from "components/common/header-bar";
import MaterialIcon from "components/common/material-icon";
import AnalyticsEvent from "models/analytics-event";
import React, { useEffect } from "react";
import AnalyticsService from "services/analytics-service";
import { UAParser } from "ua-parser-js";

const EventsPage = (props: { toggleTheme: () => void; onSiteChange: (site_id: string) => void; siteID?: string }) => {
  const theme = useEasierTheme();

  const [events, setEvents] = React.useState<AnalyticsEvent[]>([]);

  useEffect(() => {
    if (!props.siteID) return;

    new AnalyticsService().count.getEvents(props.siteID).then((resp) => {
      setEvents(resp ?? []);
    });
  }, [props.siteID]);

  return (
    <ContentArea display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <HeaderBar
        title={"Events"}
        toggleTheme={props.toggleTheme}
        onSiteChange={props.onSiteChange}
        siteID={props.siteID}
      />
      <Box
        boxSizing={"border-box"}
        padding={"0px 64px"}
        marginTop={"96px"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        width={"100%"}
        maxWidth={"1264px"}
      >
        <Box
          maxWidth={"1200px"}
          width={"100%"}
          style={{ borderRadius: "28px", border: `1px solid ${theme?.Colour.OutlineVariant()}` }}
        >
          <Table>
            <TableHead>
              <TableCell>Event</TableCell>
              <TableCell style={{ width: "256px" }}>Country</TableCell>
              <TableCell style={{ width: "96px" }}>Actions</TableCell>
            </TableHead>
            <TableBody>
              {events.map((event) => (
                <TableRow hover>
                  <TableCell>
                    <Typography variant={"h6"}>{event.title}</Typography>
                    <Typography variant={"body2"} style={{ fontWeight: 500 }}>
                      {new Date(event.ts).toString()}
                    </Typography>
                    <Typography variant={"body2"} style={{ fontWeight: 500 }}>
                      {new UAParser(event.user_agent).getBrowser().name}
                    </Typography>
                    {event.url}
                  </TableCell>
                  <TableCell>Australia</TableCell>
                  <TableCell>
                    <IconButton>
                      <MaterialIcon icon={"more_horiz"} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </ContentArea>
  );
};

export default EventsPage;
