import { ColourPalette, EasierTheme, EasierThemeProvider } from "3asier-theme";
import { SplashPage } from "3asier-ui-bits";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import Redirect from "components/common/redirect";
import DashboardPage from "components/dashboard-page";
import EventsPage from "components/events-page";
import HomePage from "components/home-page";
import VisitsPage from "components/visits-page";
import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ProfileService from "services/profile-service";
import tokens from "styles/EasierThemeTokens";
import "./App.css";
import NavBar from "./components/common/nav-bar";
import AuthService from "./services/auth-service";

function App() {
  const [signingIn, setSigningIn] = React.useState(true);

  const [muiTheme, setMuiTheme] = React.useState(
    createTheme({
      palette: {
        mode: "dark",
      },
      shape: { borderRadius: 8 },
      typography: { fontFamily: `"Roboto Flex", sans-serif` },
      components: { MuiButton: { styleOverrides: { root: { textTransform: "none" } } } },
    })
  );
  const [theme, setTheme] = React.useState<EasierTheme>(new EasierTheme(new ColourPalette(tokens), "dark"));

  const [site, setSite] = React.useState<string>();

  useEffect(() => {
    AuthService.getSession().then(() => {
      setSigningIn(false);
      new ProfileService().permissions().then((resp) => {
        setSite(resp?.[0]?.site_id);
      });
    });
  }, []);

  return (
    <ThemeProvider theme={muiTheme}>
      <EasierThemeProvider theme={theme}>
        <CssBaseline />
        {signingIn ? <SplashPage backgroundColour={theme.Colour.Surface()} /> : null}
        {!signingIn ? (
          <div style={{ background: theme.Colour.Background() }}>
            <Router>
              <Routes>
                <Route path={"/login"} element={<Redirect to={"/"} />} />
                <Route path={"/:page?"} element={<NavBar />} />
              </Routes>
              <Routes>
                <Route
                  path={"/dashboard"}
                  element={
                    <DashboardPage
                      siteID={site}
                      onSiteChange={(site_id) => setSite(site_id)}
                      toggleTheme={() => {
                        const newMode = theme.mode === "dark" ? "light" : "dark";

                        setMuiTheme(
                          createTheme({
                            palette: {
                              mode: newMode,
                            },
                            typography: { fontFamily: `"Roboto Flex", sans-serif` },
                            components: { MuiButton: { styleOverrides: { root: { textTransform: "none" } } } },
                          })
                        );
                        setTheme(new EasierTheme(new ColourPalette(tokens), newMode));
                      }}
                    />
                  }
                />
                <Route path={"/visits"} element={<VisitsPage />} />
                <Route
                  path={"/events"}
                  element={
                    <EventsPage
                      siteID={site}
                      onSiteChange={(site_id) => setSite(site_id)}
                      toggleTheme={() => {
                        const newMode = theme.mode === "dark" ? "light" : "dark";

                        setMuiTheme(
                          createTheme({
                            palette: {
                              mode: newMode,
                            },
                            shape: { borderRadius: 8 },
                            typography: { fontFamily: `"Roboto Flex", sans-serif` },
                            components: { MuiButton: { styleOverrides: { root: { textTransform: "none" } } } },
                          })
                        );
                        setTheme(new EasierTheme(new ColourPalette(tokens), newMode));
                      }}
                    />
                  }
                />
                <Route path={"/"} element={<HomePage />} />
              </Routes>
            </Router>
          </div>
        ) : null}
      </EasierThemeProvider>
    </ThemeProvider>
  );
}

export default App;
