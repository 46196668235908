import { useEasierTheme } from "3asier-theme";
import { Typography } from "@mui/material";
import React from "react";

const DashboardCardTitle = (props: { text: string }) => {
  const theme = useEasierTheme();

  return (
    <Typography variant={"h6"} textAlign={"center"} style={{ color: theme?.Colour.OnSurface() }}>
      {props.text}
    </Typography>
  );
};

export default DashboardCardTitle;
