import { useEasierTheme } from "3asier-theme";
import {
  ButtonBase,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import Permission from "models/permission";
import React, { useEffect } from "react";
import ProfileService from "services/profile-service";

const SiteSelector = (props: { onSiteChange: (site_id: string) => void; site_id?: string }) => {
  const theme = useEasierTheme();

  const [anchor, setAnchor] = React.useState<HTMLElement>();

  const [permissions, setPermissions] = React.useState<Permission[]>([]);

  useEffect(() => {
    new ProfileService().permissions().then((resp) => {
      setPermissions(resp ?? []);
    });
  }, []);

  return (
    <>
      {permissions.length < 2 ? (
        <Tooltip title={"Currently you only have access to this site."}>
          <span>
            <ButtonBase
              disabled={permissions.length < 2}
              style={{
                outline: `0px solid ${theme?.Colour.OutlineVariant()}`,
                height: "40px",
                borderRadius: "20px",
                padding: "4px 24px",
                backgroundColor: theme?.Colour.SurfaceContainerLow(),
                marginRight: "8px",
              }}
            >
              {permissions.find((p) => p.site_id === props.site_id)?.name}
            </ButtonBase>
          </span>
        </Tooltip>
      ) : (
        <ButtonBase
          disabled={permissions.length < 2}
          onClick={(e) => setAnchor(e.currentTarget)}
          style={{
            outline: `0px solid ${theme?.Colour.OutlineVariant()}`,
            height: "40px",
            borderRadius: "20px",
            padding: "4px 24px",
            backgroundColor: theme?.Colour.SurfaceContainerLow(),
            marginRight: "8px",
          }}
        >
          {permissions.find((p) => p.site_id === props.site_id)?.name}
        </ButtonBase>
      )}
      <Menu
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorEl={anchor}
        open={!!anchor}
        onClose={() => setAnchor(undefined)}
        slotProps={{ paper: { style: { minWidth: "200px" } } }}
      >
        {permissions.map((permission) => (
          <MenuItem
            onClick={() => {
              setAnchor(undefined);
              props.onSiteChange(permission.site_id);
            }}
          >
            <ListItemText primary={permission.name} secondary={permission.site_id} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SiteSelector;
