import { CognitoIdToken } from "amazon-cognito-identity-js";
import config from "../config";
import { Auth } from "aws-amplify";

export default class AuthService {
  private static token?: CognitoIdToken;
  private static path = `${config.api.PROTOCOL}://${config.api.HOSTNAME}:${config.api.PORT}${config.api.PATH}`;

  public static getToken() {
    return AuthService.token?.getJwtToken();
  }

  public static getSession() {
    return Auth.currentSession().then(
      (session) => {
        const token = session.getIdToken();
        AuthService.token = token;

        const jwt = token.getJwtToken();

        if (window.sessionStorage.getItem("auth") !== "true" && window.location.pathname !== config.LOGIN_PATH) {
          window.sessionStorage.setItem("auth", "true");

          window.location.assign(
            `${AuthService.path}/session/login?redirect_uri=${encodeURIComponent(
              window.location.origin + config.LOGIN_PATH
            )}&token=${encodeURIComponent(jwt)}`
          );

          return;
        }
        return session;
      },
      async () => {
        window.sessionStorage.removeItem("auth");
        Auth.federatedSignIn();
      }
    );
  }
}
