import { EzrImage } from "3asier-ui-bits";
import { Box, Button, Divider, Fade } from "@mui/material";
import React from "react";
import logo_black from "../../../../assets/Percipium Logo Black RGB.png";
import logo_white from "../../../../assets/Percipium Logo White RGB.png";
import MaterialIcon from "components/common/material-icon";
import { useEasierTheme } from "3asier-theme";
import NavListOption from "../nav-list-option";
import { useNavigate, useParams } from "react-router-dom";

const NavContentLarge = (props: { in: boolean }) => {
  const theme = useEasierTheme();

  const currentPage = useParams()["page"];
  const navigate = useNavigate();

  return (
    <Fade in={props.in} mountOnEnter unmountOnExit>
      <Box
        top={0}
        left={0}
        position={props.in ? "relative" : "absolute"}
        width={"300px"}
        display={"flex"}
        flexDirection={"column"}
        padding={"12px 12px"}
      >
        <Button
          color={"secondary"}
          onClick={() => {
            navigate("/");
          }}
          style={{
            height: "56px",
            borderRadius: "28px",
            padding: "0px 16px",
            backgroundColor: !currentPage ? theme?.Colour.Secondary() : undefined,
            color: !currentPage ? theme?.Colour.OnSecondary() : theme?.Colour.OnSurfaceVariant(),
            justifyContent: "flex-start",
          }}
        >
          <EzrImage
            src={
              theme?.mode === "dark"
                ? !!currentPage
                  ? logo_white
                  : logo_black
                : !!currentPage
                ? logo_black
                : logo_white
            }
            style={{ height: "44px", marginRight: "12px", marginTop: "5px" }}
          />
        </Button>
        <Divider style={{ margin: "16px 0px", borderColor: theme?.Colour.OutlineVariant() }} />
        <NavListOption
          text={"Dashboard"}
          icon={"space_dashboard"}
          onClick={() => {
            navigate("/dashboard");
          }}
          selected={currentPage === "dashboard"}
        />
        {false && (
          <NavListOption
            text={"Visits"}
            icon={"note_stack"}
            onClick={() => {
              navigate("/visits");
            }}
            selected={currentPage === "visits"}
          />
        )}
        <NavListOption
          text={"Events"}
          icon={"flowsheet"}
          onClick={() => {
            navigate("/events");
          }}
          selected={currentPage === "events"}
        />
      </Box>
    </Fade>
  );
};

export default NavContentLarge;
