import { useEasierTheme } from "3asier-theme";
import { Box, CircularProgress } from "@mui/material";
import { Chart } from "chart.js/auto";
import React, { useEffect } from "react";
import AnalyticsService from "services/analytics-service";
import "chartjs-adapter-luxon";

const ChartEventsPerDay = (props: {
  site_id: string;
  startDay: number | undefined;
  endDay: number | undefined;
  binSize: number;
}) => {
  const theme = useEasierTheme();

  const [id] = React.useState(`${Math.random()}`);

  const [data, setData] = React.useState<{ day: number; count: number }[]>();

  useEffect(() => {
    if (props.startDay === undefined || props.endDay === undefined) return;

    new AnalyticsService().count
      .eventsPerDay(props.site_id, props.startDay, props.endDay, props.binSize)
      .then((resp) => {
        setData(resp);
      });
  }, [props.site_id, props.startDay, props.endDay, props.binSize]);

  useEffect(() => {
    if (!data) return;

    const canvas = document.getElementById(id) as HTMLCanvasElement | undefined;

    if (!canvas) return;

    var gradient = canvas.getContext("2d")?.createLinearGradient(0, 0, 0, 175);
    gradient?.addColorStop(0, `${theme?.Colour.Secondary()}A0`);
    gradient?.addColorStop(1, `${theme?.Colour.Secondary()}00`);

    const chart = new Chart(canvas, {
      type: "bar",
      data: {
        labels: data.map((d) => new Date(d.day).toLocaleDateString()),
        datasets: [
          {
            borderColor: theme?.Colour.Outline(),
            label: "Site hits",
            data: data.map((d) => ({ x: d.day, y: d.count })),
            borderWidth: 1,
          },
        ],
      },
      options: {
        font: { family: "Roboto Flex" },
        maintainAspectRatio: false,
        bar: {
          datasets: {
            barPercentage: 2,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            suggestedMax: (Math.max(...data.map((d) => d.count)) * 1.1) | 0,
          },
          x: {
            min: Math.min(...data.map((d) => d.day)) ?? props.startDay ?? 0,
            max: props.endDay ?? Date.now(),
            type: "time",
            ticks: {
              callback: (tickValue, index, ticks) =>
                props.binSize < 60_000 * 60 * 24
                  ? `${new Date(tickValue).toLocaleTimeString()}`
                  : `${new Date(tickValue).toLocaleDateString()}`,
            },
          },
        },
        datasets: {
          line: {
            pointStyle: false,
          },
        },
        parsing: false,
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  return !!data ? (
    <Box width={"100%"} height={"100%"} overflow={"hidden"}>
      <canvas id={id} style={{ width: "100%", height: "100%" }} />
    </Box>
  ) : (
    <CircularProgress />
  );
};

export default ChartEventsPerDay;
