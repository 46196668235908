import { useEasierTheme } from "3asier-theme";
import { Box, Typography } from "@mui/material";
import React from "react";

import dashboard_image from "../dashboard.png";

const NavCard = (props: { onClick?: () => void; title: string; image: string }) => {
  const theme = useEasierTheme();

  const [hover, setHover] = React.useState(false);

  return (
    <Box
      onMouseMove={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        backgroundColor: theme?.Colour.SurfaceContainer(),
        borderRadius: "24px",
        overflow: "hidden",
        cursor: "pointer",
        transition: "all 300ms cubic-bezier(0.2, 0, 0, 1)",
      }}
      onClick={props.onClick}
    >
      <Box overflow={"hidden"} borderRadius={"24px"} width={"100%"} height={"256px"}>
        <img src={props.image} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
      </Box>
      <Box padding={"24px"}>
        <Typography
          style={{
            color: theme?.Colour.OnSurface(),
            fontSize: "1.5rem",
            lineHeight: "2rem",
            fontWeight: 475,
            letterSpacing: "0rem",
            fontVariationSettings: `"opsz" 18`,
          }}
        >
          {props.title}
        </Typography>
      </Box>
    </Box>
  );
};

export default NavCard;
