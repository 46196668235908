import { Box, BoxProps, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

const ContentArea = (props: BoxProps) => {
  const muiTheme = useTheme();
  const breakpointLg = useMediaQuery(muiTheme.breakpoints.up("lg"));
  const breakpointMd = useMediaQuery(muiTheme.breakpoints.up("sm"));

  return (
    <Box
      marginLeft={breakpointLg ? "300px" : breakpointMd ? "88px" : "0px"}
      {...props}
      style={{
        minHeight: "100vh",
        transition: "margin-left 300ms cubic-bezier(0.2, 0, 0, 1)",
        position: "relative",
        ...props?.style,
      }}
    ></Box>
  );
};

export default ContentArea;
