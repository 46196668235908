import { Box, Button, Dialog, Divider, IconButton, Slide, Tooltip, Typography, Zoom } from "@mui/material";
import React, { useEffect } from "react";
import YearSelector from "./YearSelector";
import { useEasierTheme } from "3asier-theme";
import MaterialIcon from "../material-icon";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MONTHS_SHORT = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const DAYS_SHORT = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];

const DatePicker = (props: {
  open: boolean;
  onClose: () => void;
  value?: string;
  onChange: (value: string) => void;
}) => {
  const easierTheme = useEasierTheme();

  const [year, setYear] = React.useState(props.value?.split("/")[0] ? parseInt(props.value?.split("/")[0]) : undefined);
  const [month, setMonth] = React.useState(
    props.value?.split("/")[1] ? parseInt(props.value?.split("/")[1]) : undefined
  );
  const [day, setDay] = React.useState(props.value?.split("/")[2] ? parseInt(props.value?.split("/")[2]) : undefined);

  const [selectedYear, setSelectedYear] = React.useState(
    props.value?.split("/")[0] ? parseInt(props.value?.split("/")[0]) : new Date().getFullYear()
  );
  const [selectedMonth, setSelectedMonth] = React.useState(
    props.value?.split("/")[1] ? parseInt(props.value?.split("/")[1]) : new Date().getMonth() + 1
  );

  const [transitionDir, setTransitionDir] = React.useState<"left" | "right">();

  const [value, setValue] = React.useState(new Date(new Date().getTime() * 2 * Math.random()));

  const [yearDrawerOpen, setYearDrawerOpen] = React.useState(false);

  useEffect(() => {
    const y = props.value?.split("/")[0] ? parseInt(props.value?.split("/")[0]) : undefined;
    const m = props.value?.split("/")[1] ? parseInt(props.value?.split("/")[1]) : undefined;
    const d = props.value?.split("/")[2] ? parseInt(props.value?.split("/")[2]) : undefined;

    setYear(y);
    setMonth(m);
    setDay(d);
  }, [props.value]);

  useEffect(() => {
    if (!props.open) return;

    setSelectedYear(props.value?.split("/")[0] ? parseInt(props.value?.split("/")[0]) : new Date().getFullYear());
    setSelectedMonth(props.value?.split("/")[1] ? parseInt(props.value?.split("/")[1]) : new Date().getMonth() + 1);
  }, [props.open]);

  const daysInSelectedMonth = new Date(selectedYear, selectedMonth, 0).getDate();
  const firstDayOfSelectedMonth = new Date(selectedYear, selectedMonth - 1).getDay();

  const isToday = (year: number, month: number, day: number) =>
    new Date().getFullYear() === year && new Date().getMonth() === month - 1 && day === new Date().getDate();
  const isValue = (y: number, m: number, d: number) => y === year && m === month && d === day;

  useEffect(() => {
    setTimeout(() => setTransitionDir(undefined), 10);
  }, [transitionDir]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{ style: { background: "transparent", boxShadow: "none" } }}
    >
      <Box
        style={{
          backgroundColor: easierTheme?.Colour.SurfaceContainerHigh(),
          borderRadius: "28px",
          overflow: "hidden",
          width: "360px",
          height: "557px",
        }}
      >
        <Box width={"360px"} height={"120px"} display={"flex"} padding={"16px 12px 12px 24px"}>
          <Box width={"100%"}>
            <Typography
              variant={"body2"}
              lineHeight={"1rem"}
              fontSize={"0.75rem"}
              fontWeight={500}
              color={easierTheme?.Colour.OnSurfaceVariant()}
              style={{ letterSpacing: "0.0416rem", fontVariationSettings: `"opsz" 12` }}
            >
              Select date
            </Typography>
            <Typography
              marginTop={"36px"}
              variant={"body2"}
              lineHeight={"2.5rem"}
              fontSize={"2rem"}
              fontWeight={400}
              color={easierTheme?.Colour.OnSurfaceVariant()}
              style={{ letterSpacing: "0rem" }}
            >
              {day === undefined || month === undefined || year === undefined ? (
                "Select Date..."
              ) : (
                <>
                  {
                    DAYS_SHORT[
                      new Date(year ?? new Date().getFullYear(), (month ?? new Date().getMonth()) - 1, day).getDay()
                    ]
                  }
                  , {MONTHS_SHORT[(month ?? new Date().getMonth()) - 1]} {day}
                </>
              )}
            </Typography>
          </Box>
          <IconButton style={{ marginLeft: "auto", width: "48px", height: "48px", marginTop: "auto" }}>
            <MaterialIcon icon={"edit"} style={{ color: easierTheme?.Colour.OnSurfaceVariant() }} />
          </IconButton>
        </Box>
        <Divider />
        <Box display={"flex"} padding={"4px 12px"} alignItems={"center"}>
          <Button
            style={{ color: easierTheme?.Colour.OnSurfaceVariant(), height: "40px", padding: "6px 12px" }}
            onClick={() => setYearDrawerOpen(!yearDrawerOpen)}
          >
            {MONTHS[selectedMonth - 1]} {selectedYear}
            <MaterialIcon style={{ fontSize: "inherit", marginLeft: "4px" }} icon={"expand_more"} />
          </Button>
          <Zoom in={!yearDrawerOpen}>
            <IconButton
              style={{
                color: easierTheme?.Colour.OnSurfaceVariant(),
                width: "48px",
                height: "48px",
                marginLeft: "auto",
              }}
              onClick={() => {
                setTransitionDir("left");
                setSelectedMonth((11 + selectedMonth) % 12 || 12);
                if (selectedMonth === 1) {
                  setSelectedYear(selectedYear - 1);
                }
              }}
            >
              <MaterialIcon icon={"chevron_left"} style={{ color: easierTheme?.Colour.OnSurfaceVariant() }} />
            </IconButton>
          </Zoom>
          <Zoom in={!yearDrawerOpen}>
            <IconButton
              style={{ color: easierTheme?.Colour.OnSurfaceVariant(), width: "48px", height: "48px" }}
              onClick={() => {
                setTransitionDir("right");
                setSelectedMonth((13 + selectedMonth) % 12 || 12);
                if (selectedMonth === 12) {
                  setSelectedYear(selectedYear + 1);
                }
              }}
            >
              <MaterialIcon icon={"chevron_right"} style={{ color: easierTheme?.Colour.OnSurfaceVariant() }} />
            </IconButton>
          </Zoom>
        </Box>
        <Box position={"relative"} overflow={"hidden"}>
          <Box
            key={`${selectedYear}/${selectedMonth}`}
            height={"328px"}
            width={"360px"}
            style={{
              opacity: !!transitionDir ? 0 : 1,
              transform: `translateX(${!transitionDir ? 0 : transitionDir === "left" ? -64 : 64}px)`,
              transition: `all 300ms cubic-bezier(0.2, 0, 0, 1)`,
            }}
          >
            <Box
              display={"grid"}
              gridTemplateColumns={"repeat(7, 40px)"}
              gridAutoRows={"40px"}
              gap={"8px"}
              padding={"0px 16px 0px 16px"}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                color={easierTheme?.Colour.OnSurface()}
                style={{
                  lineHeight: "1.25rem",
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  letterSpacing: "0.01785rem",
                }}
              >
                <Tooltip title={"Sunday"}>
                  <span>S</span>
                </Tooltip>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                color={easierTheme?.Colour.OnSurface()}
                style={{
                  lineHeight: "1.25rem",
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  letterSpacing: "0.01785rem",
                }}
              >
                <Tooltip title={"Monday"}>
                  <span>M</span>
                </Tooltip>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                color={easierTheme?.Colour.OnSurface()}
                style={{
                  lineHeight: "1.25rem",
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  letterSpacing: "0.01785rem",
                }}
              >
                <Tooltip title={"Tuesday"}>
                  <span>T</span>
                </Tooltip>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                color={easierTheme?.Colour.OnSurface()}
                style={{
                  lineHeight: "1.25rem",
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  letterSpacing: "0.01785rem",
                }}
              >
                <Tooltip title={"Wednesday"}>
                  <span>W</span>
                </Tooltip>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                color={easierTheme?.Colour.OnSurface()}
                style={{
                  lineHeight: "1.25rem",
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  letterSpacing: "0.01785rem",
                }}
              >
                <Tooltip title={"Thursday"}>
                  <span>T</span>
                </Tooltip>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                color={easierTheme?.Colour.OnSurface()}
                style={{
                  lineHeight: "1.25rem",
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  letterSpacing: "0.01785rem",
                }}
              >
                <Tooltip title={"Friday"}>
                  <span>F</span>
                </Tooltip>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                color={easierTheme?.Colour.OnSurface()}
                style={{
                  lineHeight: "1.25rem",
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  letterSpacing: "0.01785rem",
                }}
              >
                <Tooltip title={"Saturday"}>
                  <span>S</span>
                </Tooltip>
              </Box>
            </Box>
            <Box
              display={"grid"}
              gridAutoColumns={"40px"}
              gridTemplateColumns={"repeat(7, 40px)"}
              gridAutoRows={"40px"}
              gap={"8px"}
              padding={"4px 16px"}
            >
              {Array.from(new Array(firstDayOfSelectedMonth)).map(() => (
                <span></span>
              ))}
              {Array.from(new Array(daysInSelectedMonth))
                .map((_, i) => i + 1)
                .map((i) => (
                  <IconButton
                    key={`${selectedYear}-${selectedMonth}-${i}`}
                    size={"small"}
                    style={{
                      color: isValue(selectedYear, selectedMonth, i)
                        ? easierTheme?.Colour.OnPrimary()
                        : isToday(selectedYear, selectedMonth, i)
                        ? easierTheme?.Colour.Primary()
                        : easierTheme?.Colour.OnSurface(),
                      lineHeight: "1.25rem",
                      fontSize: "0.875rem",
                      fontWeight: 400,
                      letterSpacing: "0.01785rem",
                      background: isValue(selectedYear, selectedMonth, i) ? easierTheme?.Colour.Primary() : undefined,
                      border: isToday(selectedYear, selectedMonth, i)
                        ? `1px solid ${easierTheme?.Colour.Primary()}`
                        : `none`,
                      fontFamily: "Roboto Flex",
                    }}
                    onClick={() => {
                      setYear(selectedYear);
                      setMonth(selectedMonth);
                      setDay(i);
                    }}
                  >
                    {i}
                  </IconButton>
                ))}
            </Box>
          </Box>
          <YearSelector
            open={yearDrawerOpen}
            onClose={() => setYearDrawerOpen(false)}
            menuButtonText={`${MONTHS[selectedMonth - 1]} ${year}`}
            year={selectedYear}
            onChangeYear={(y) => setSelectedYear(y)}
          />
        </Box>
        <Box display={"flex"} padding={"8px 12px 12px 12px"}>
          <Button
            size={"small"}
            style={{ marginLeft: "auto", color: easierTheme?.Colour.Primary() }}
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button
            size={"small"}
            style={{
              marginLeft: "8px",
              color: easierTheme?.Colour.Primary(),
            }}
            onClick={() => {
              props.onChange(
                `${(year ?? new Date().getFullYear()).toString().padStart(4, "0")}/${(month ?? new Date().getMonth())
                  .toString()
                  .padStart(2, "0")}/${(day ?? new Date().getDate()).toString().padStart(2, "0")}`
              );
              props.onClose?.();
            }}
          >
            OK
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DatePicker;
