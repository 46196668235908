import { useEasierTheme } from "3asier-theme";
import { Box, ButtonBase, Typography } from "@mui/material";
import User from "models/user";
import logo from "../../../assets/Percipium-Circle-icon-RGB-white.png";

const AccountButton = (props: { onClick?: (el: HTMLElement) => void; user?: User }) => {
  const theme = useEasierTheme();

  return (
    <ButtonBase
      onClick={(e) => props.onClick?.(e.currentTarget)}
      style={{
        outline: `0px solid ${theme?.Colour.OutlineVariant()}`,
        height: "40px",
        borderRadius: "20px",
        padding: "4px",
        maxWidth: "192px",
        backgroundColor: theme?.Colour.SurfaceContainerLow(),
        paddingRight: "12px",
      }}
    >
      <Box
        width={"32px"}
        minWidth={"32px"}
        height={"32px"}
        borderRadius={"16px"}
        marginRight={"8px"}
        style={{ backgroundColor: theme?.Colour.OutlineVariant() }}
      >
        {logo ? <img style={{ width: "100%", height: "100%" }} src={logo} /> : props.user?.name.slice(0, 1)}
      </Box>
      <Typography
        color={theme?.Colour.OnBackground()}
        style={{ fontSize: "0.875rem", lineHeight: "1.25rem", fontWeight: 500 }}
        noWrap
      >
        {props.user?.name}
      </Typography>
    </ButtonBase>
  );
};

export default AccountButton;
