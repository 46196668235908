import { useEasierTheme } from "3asier-theme";
import { Box, ButtonBase } from "@mui/material";
import ChartEventsPerDay from "components/charts/ChartEventsPerDay";
import React, { useEffect } from "react";
import DashboardCard from "../DashboardCard";
import DashboardCardTitle from "../DashboardCardTitle";

const VisitsPerDayCard = (props: { site_id: string; startTime?: number; endTime?: number }) => {
  const theme = useEasierTheme();

  const [binSize, setBinSize] = React.useState(
    (props.endTime ?? 0) - (props.startTime ?? 0) < 60_000 * 60 * 24 ? 60_000 * 60 : 60_000 * 60 * 24
  );

  useEffect(() => {
    if ((props.endTime ?? 0) - (props.startTime ?? 0) > 60_000 * 60 * 24 * 7 && binSize < 60_000 * 60 * 24) {
      setBinSize(60_000 * 60 * 24);
    }
  }, [props.startTime, props.endTime]);

  return (
    <DashboardCard rowSpan={3} colSpan={3}>
      <DashboardCardTitle text={"Views over Time"} />
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flex={1}
        overflow={"hidden"}
        padding={"8px"}
        width={"100%"}
      >
        <ChartEventsPerDay
          site_id={props.site_id}
          startDay={props.startTime}
          endDay={props.endTime}
          binSize={binSize}
        />
      </Box>
      <Box
        height={"40px"}
        style={{
          width: "100%",
          borderRadius: "20px",
          border: `1px solid ${theme?.Colour.Outline()}`,
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <ButtonBase
          style={{
            flex: 1,
            height: "100%",
            background: binSize === 60_000 * 60 ? theme?.Colour.SecondaryContainer() : undefined,
          }}
          onClick={() => setBinSize(60_000 * 60)}
        >
          Hour
        </ButtonBase>
        <span style={{ width: "1px", height: "100%", background: theme?.Colour.Outline() }}></span>
        <ButtonBase
          style={{
            flex: 1,
            height: "100%",
            background: binSize === 60_000 * 60 * 24 ? theme?.Colour.SecondaryContainer() : undefined,
          }}
          onClick={() => setBinSize(60_000 * 60 * 24)}
        >
          Day
        </ButtonBase>
        <span style={{ width: "1px", height: "100%", background: theme?.Colour.Outline() }}></span>
        <ButtonBase
          style={{
            flex: 1,
            height: "100%",
            background: binSize === 60_000 * 60 * 24 * 7 ? theme?.Colour.SecondaryContainer() : undefined,
          }}
          onClick={() => setBinSize(60_000 * 60 * 24 * 7)}
        >
          Week
        </ButtonBase>
      </Box>
    </DashboardCard>
  );
};

export default VisitsPerDayCard;
