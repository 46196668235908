import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import DatePicker from "../date-picker";
import MaterialIcon from "../material-icon";

const DateField = (
  props: Omit<TextFieldProps, "onChange" | "value"> & { onChange?: (val: string) => void; value?: string }
) => {
  const { onChange, ...fieldProps } = props;
  const [open, setOpen] = React.useState(false);

  const [value, setValue] = React.useState(props.value);

  return (
    <>
      <TextField
        InputProps={{
          startAdornment: fieldProps.InputProps?.startAdornment ?? (
            <MaterialIcon icon={"calendar_today"} style={{ marginRight: "8px" }} />
          ),
        }}
        onClick={() => setOpen(true)}
        value={value ?? null}
        placeholder={props.placeholder || "yyyy/mm/dd"}
        inputProps={{ ...fieldProps.inputProps, style: { cursor: "default", ...fieldProps.inputProps?.style } }}
        {...fieldProps}
      />
      <DatePicker
        open={open}
        onClose={() => setOpen(false)}
        onChange={(val) => {
          setValue(val);
          onChange?.(val);
        }}
        value={props.value}
      />
    </>
  );
};

export default DateField;
