import { useEasierTheme } from "3asier-theme";
import { Box } from "@mui/material";
import { Chart } from "chart.js/auto";
import React, { useEffect } from "react";
import AnalyticsService from "services/analytics-service";
import { UAParser } from "ua-parser-js";

const ChartBrowserByUniqueIP = (props: {
  site_id: string;
  startDay: number | undefined;
  endDay: number | undefined;
}) => {
  const theme = useEasierTheme();
  const [id] = React.useState(`${Math.random()}`);

  const [data, setData] = React.useState<{ user_agent: string; ip: string; count: string }[]>();

  useEffect(() => {
    if (props.startDay === undefined || props.endDay === undefined) return;

    new AnalyticsService().count.browserByUniqueIP(props.site_id, props.startDay, props.endDay).then((resp) => {
      setData(resp as any);
    });
  }, [props.site_id, props.startDay, props.endDay]);

  useEffect(() => {
    const canvas = document.getElementById(id) as HTMLCanvasElement | undefined;

    if (!canvas) return;

    if (!data) return;

    const browserCounts: { [browser: string]: number } = {};

    for (let i = 0; i < data.length; i++) {
      const ua = new UAParser(data[i].user_agent).getBrowser().name ?? "Unknown";

      browserCounts[ua] = (browserCounts[ua] ?? 0) + 1;
    }

    const browsersWithCounts = Object.entries(browserCounts)
      .map((e) => ({ name: e[0], count: e[1] }))
      .sort((a, b) => ((a.count ?? 0) > (b.count ?? 0) ? -1 : 1));

    const top10Browsers = browsersWithCounts?.slice(0, 10);
    const otherBrowsersCount = browsersWithCounts?.slice(10).reduce((z, x) => z + (x.count ?? 0), 0);

    const finalBrowsers = [...(top10Browsers ?? [])];

    if ((otherBrowsersCount ?? 0) > 0) finalBrowsers.push({ name: "Other", count: otherBrowsersCount });

    if (!finalBrowsers.length) return;

    const chart = new Chart(canvas, {
      type: "bar",
      data: {
        labels: finalBrowsers.map((b) => b.name),
        datasets: [
          {
            borderColor: theme?.Colour.Outline(),
            backgroundColor: `${theme?.Colour.Secondary()}30`,
            label: "Unique IPs",
            data: finalBrowsers.map((b) => b.count),
            borderWidth: 1,
          },
        ],
      },
      options: {
        font: { family: "Roboto Flex" },
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <Box width={"100%"} height={"100%"} overflow={"hidden"}>
      <canvas id={id} style={{ width: "100%", height: "100%" }} />
    </Box>
  );
};

export default ChartBrowserByUniqueIP;
