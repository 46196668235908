import { Box, Typography } from "@mui/material";
import ChartEventsByCountry from "components/charts/ChartEventsByCountry";
import ContentArea from "components/common/content-area";
import DateFilter from "components/common/date-filter";
import HeaderBar from "components/common/header-bar";
import React from "react";
import ChartBrowserByUniqueIP from "../charts/ChartBrowserByUniqueIP";
import DashboardCard from "./DashboardCard";
import VisitsPerDayCard from "./VisitsPerDayCard";
import CardTotalDownloads from "./card-total-downloads";
import CardTotalUniqueIPs from "./card-total-unique-ips";
import CardTotalVisits from "./card-total-visits";

const DashboardPage = (props: {
  toggleTheme: () => void;
  onSiteChange: (site_id?: string) => void;
  siteID?: string;
}) => {
  const [startTime, setStartTime] = React.useState<number>();
  const [endTime, setEndTime] = React.useState<number>();

  const debounce = React.useRef<NodeJS.Timeout>();

  return (
    <ContentArea display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <HeaderBar
        title={"Dashboard"}
        toggleTheme={props.toggleTheme}
        onSiteChange={props.onSiteChange}
        siteID={props.siteID}
      />
      <DateFilter
        maxWidth={"1264px"}
        width={"100%"}
        padding={"0px 64px"}
        marginTop={"96px"}
        onChangeDate={(val) => {
          if (debounce.current) clearTimeout(debounce.current);
          debounce.current = setTimeout(() => {
            setStartTime(val.startTime);
            setEndTime(val.endTime);
          }, 500);
        }}
      />
      {props.siteID && (
        <Box
          width={"100%"}
          maxWidth={"1264px"}
          display={"grid"}
          marginTop={"64px"}
          gridTemplateColumns={`repeat(auto-fill, minmax(200px, 1fr))`}
          gridRow={"repeat(auto-fill, 200px)"}
          gap={"8px"}
          padding={"0px 64px 256px 64px"}
        >
          <CardTotalVisits site_id={props.siteID} startTime={startTime} endTime={endTime} />
          <CardTotalDownloads site_id={props.siteID} startTime={startTime} endTime={endTime} />
          <VisitsPerDayCard site_id={props.siteID} startTime={startTime} endTime={endTime} />
          <DashboardCard rowSpan={3} colSpan={2}>
            <Typography variant={"h5"} textAlign={"center"}>
              User Agents by Unique IP Address
            </Typography>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100%"}>
              <ChartBrowserByUniqueIP site_id={props.siteID} startDay={startTime} endDay={endTime} />
            </Box>
          </DashboardCard>
          <CardTotalUniqueIPs site_id={props.siteID} startTime={startTime} endTime={endTime} />
          <DashboardCard rowSpan={3} colSpan={2}>
            <Typography variant={"h5"} textAlign={"center"}>
              Page Views by Country
            </Typography>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100%"}>
              <ChartEventsByCountry site_id={props.siteID} startDay={startTime} endDay={endTime} />
            </Box>
          </DashboardCard>
        </Box>
      )}
    </ContentArea>
  );
};

export default DashboardPage;
