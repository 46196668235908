import RestClient from "./rest-client";
import AnalyticsEvent from "models/analytics-event";

export default class AnalyticsService extends RestClient {
  public events = {
    count: async (site_id: string, startTime: number, endTime: number, event_name: string) => {
      const url = this.buildURL(
        "/analytics/site_events/count",
        ["site_id", site_id],
        ["start_date", startTime],
        ["end_date", endTime],
        ["event_name", event_name]
      );

      const r = await fetch(url, {
        headers: this.headers,
      });
      if (r.ok) {
        const o = (await r.json()) as [{ count: number }];
        return o;
      }
    },
    ip: {
      count: async (site_id: string, startTime: number, endTime: number) => {
        const url = this.buildURL(
          "/analytics/site_events/ip/count",
          ["site_id", site_id],
          ["start_date", startTime],
          ["end_date", endTime]
        );

        const r = await fetch(url, {
          headers: this.headers,
        });
        if (r.ok) {
          const o = (await r.json()) as [{ count: number }];
          return o;
        }
      },
    },
    pages: {
      count: async (site_id: string, startTime: number, endTime: number) => {
        const url = this.buildURL(
          "/analytics/site_events/pages/count",
          ["site_id", site_id],
          ["start_date", startTime],
          ["end_date", endTime]
        );

        const r = await fetch(url, {
          headers: this.headers,
        });
        if (r.ok) {
          const o = (await r.json()) as [{ count: number }];
          return o;
        }
      },
    },
  };

  public count = {
    browserByUniqueIP: async (site_id: string, startDate?: number, endDate?: number) => {
      const url = this.buildURL(
        "/analytics/site_events",
        ["site_id", site_id],
        ["start_date", startDate],
        ["end_date", endDate]
      );

      const r = await fetch(url, {
        headers: this.headers,
      });
      if (r.ok) {
        const o = (await r.json()) as AnalyticsEvent[];
        return o;
      }
    },
    countriesByUniqueIP: async (site_id: string, startDate?: number, endDate?: number) => {
      const url = this.buildURL(
        "/analytics/site_events/countries_by_unique_ip",
        ["site_id", site_id],
        ["start_date", startDate],
        ["end_date", endDate]
      );

      const r = await fetch(url, {
        headers: this.headers,
      });
      if (r.ok) {
        const o = (await r.json()) as { count: number; country_name: string | null }[];
        return o;
      }
    },
    eventsPerDay: async (site_id: string, startDate?: number, endDate?: number, binSize?: number) => {
      const url = this.buildURL(
        "/analytics/site_events/events_per_day",
        ["site_id", site_id],
        ["start_date", startDate],
        ["end_date", endDate],
        ["bin", binSize]
      );

      const r = await fetch(url, {
        headers: this.headers,
      });
      if (r.ok) {
        const o = (await r.json()) as { day: number; count: number }[];
        return o;
      }
    },
    getEvents: async (site_id: string, startDate?: number, endDate?: number, rows: number = 30) => {
      const url = this.buildURL(
        "/analytics/site_events/list",
        ["site_id", site_id],
        ["start_date", startDate],
        ["end_date", endDate],
        ["rows", rows]
      );

      const r = await fetch(url, {
        headers: this.headers,
      });
      if (r.ok) {
        const o = (await r.json()) as AnalyticsEvent[];
        return o;
      }
    },
  };
}
