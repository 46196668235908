import { useEasierTheme } from "3asier-theme";
import { Button } from "@mui/material";
import MaterialIcon from "components/common/material-icon";
import React from "react";

const NavListOption = (props: { text: string; icon?: string; onClick: () => void; selected: boolean }) => {
  const theme = useEasierTheme();

  return (
    <Button
      onClick={props.onClick}
      color={"secondary"}
      style={{
        backgroundColor: props.selected ? theme?.Colour.Secondary() : undefined,
        height: "56px",
        borderRadius: "28px",
        padding: "0px 16px",
        color: props.selected ? theme?.Colour.OnSecondary() : theme?.Colour.OnSurfaceVariant(),
        justifyContent: "flex-start",
      }}
    >
      <MaterialIcon
        icon={props.icon}
        style={{
          marginRight: "12px",
          transition: "all 300ms ease",
          fontVariationSettings: `"FILL" ${props.selected ? 1 : 0}`,
        }}
      />
      {props.text}
    </Button>
  );
};

export default NavListOption;
