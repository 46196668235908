import { useEasierTheme } from "3asier-theme";
import { Box, Button, Divider } from "@mui/material";
import { useEffect } from "react";

const YearSelector = (props: {
  open?: boolean;
  onClose: () => void;
  menuButtonText: string;
  year: number;
  onChangeYear: (year: number) => void;
}) => {
  const easierTheme = useEasierTheme();

  useEffect(() => {
    if (!props.open) return;

    const buttonEl = document.getElementById(`year-selector-${props.year}`);
    buttonEl?.scrollIntoView({ block: "center" });
  }, [props.open]);

  return (
    <Box
      height={"328px"}
      width={"360px"}
      position={"absolute"}
      left={0}
      bottom={props.open ? "0%" : "100%"}
      style={{ opacity: props.open ? 1 : 0, transition: `all 300ms cubic-bezier(0.2, 0, 0, 1)` }}
      display={"flex"}
      flexDirection={"column"}
      bgcolor={easierTheme?.Colour.SurfaceContainerHigh()}
    >
      <Box flex={1} overflow={"auto"} className={"scrollbar-none"} padding={"18px"}>
        <Box display={"grid"} gridTemplateColumns={"repeat(3, auto)"} gridAutoRows={"40px"} gap={"18px"}>
          {Array.from(new Array(201))
            .map((_, i) => i + 1900)
            .map((y) => (
              <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <Button
                  id={`year-selector-${y}`}
                  onClick={() => props.onChangeYear(y)}
                  style={{
                    width: "72px",
                    height: "36px",
                    color: props.year === y ? easierTheme?.Colour.OnPrimary() : easierTheme?.Colour.OnSurfaceVariant(),
                    backgroundColor: props.year === y ? easierTheme?.Colour.Primary() : undefined,
                    lineHeight: "1.5rem",
                    fontSize: "1rem",
                    fontWeight: 400,
                    letterSpacing: "0.03125rem",
                  }}
                >
                  {y}
                </Button>
              </Box>
            ))}
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

export default YearSelector;
