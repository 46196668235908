import React, { useEffect } from "react";
import AnalyticsService from "services/analytics-service";
import DashboardCard from "../DashboardCard";
import { Typography } from "@mui/material";
import { useEasierTheme } from "3asier-theme";

const CardTotalUniqueIPs = (props: { site_id: string; startTime?: number; endTime?: number }) => {
  const theme = useEasierTheme();
  const [count, setCount] = React.useState(0);

  useEffect(() => {
    if (props.startTime === undefined || props.endTime === undefined) return;

    new AnalyticsService().events.ip.count(props.site_id, props.startTime, props.endTime).then((resp) => {
      console.log(count);
      setCount(resp?.[0].count ?? 0);
    });
  }, [props.site_id, props.startTime, props.endTime]);

  return (
    <DashboardCard>
      <Typography variant={"h6"} textAlign={"center"} style={{ color: theme?.Colour.OnSurface() }}>
        Unique IPs
      </Typography>
      <Typography variant={"h4"}>{count}</Typography>
    </DashboardCard>
  );
};

export default CardTotalUniqueIPs;
