import { useEasierTheme } from "3asier-theme";
import { Box, BoxProps } from "@mui/material";
import React from "react";

const DashboardCard = (props: BoxProps & { rowSpan?: number; colSpan?: number }) => {
  const { rowSpan, colSpan, ...boxProps } = props;

  const theme = useEasierTheme();

  return (
    <Box
      padding={"16px"}
      {...boxProps}
      style={{
        backgroundColor: theme?.Colour.SurfaceContainer(),
        borderRadius: "24px",
        height: `${128 * (rowSpan ?? 1) + 8 * ((rowSpan ?? 0) - 1)}px`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gridRow: `span ${rowSpan ?? 1}`,
        gridColumn: `span ${colSpan ?? 1}`,
        ...boxProps.style,
      }}
    ></Box>
  );
};

export default DashboardCard;
