import User from "models/user";
import RestClient from "./rest-client";
import Permission from "models/permission";

export default class ProfileService extends RestClient {
  public async get() {
    const url = this.buildURL("/profile/me");

    const r = await fetch(url, {
      headers: this.headers,
    });
    if (r.ok) {
      const o = (await r.json()) as User;
      return o;
    }
  }

  public async permissions() {
    const url = this.buildURL("/profile/permissions");

    const r = await fetch(url, {
      headers: this.headers,
    });
    if (r.ok) {
      const o = (await r.json()) as Permission[];
      return o;
    }
  }
}
