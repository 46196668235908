import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const Redirect = (props: { to: string }) => {
  const params = useSearchParams()[0];
  const navigate = useNavigate();

  useEffect(() => {
    const to = props.to;

    navigate(to, { replace: true });
  }, [navigate, params, props.to]);

  return null;
};

export default Redirect;
