import { useEasierTheme } from "3asier-theme";
import React from "react";
import logoDark from "../../../assets/Percipium-icon-RGB-Black.png";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import NavContentLarge from "./nav-content-large";
import NavContentMedium from "./nav-content-medium";

const NavBar = () => {
  const theme = useEasierTheme();

  const muiTheme = useTheme();
  const breakpointLg = useMediaQuery(muiTheme.breakpoints.up("lg"));
  const breakpointMd = useMediaQuery(muiTheme.breakpoints.up("sm"));

  return (
    <nav
      style={{
        background: breakpointLg ? theme?.Colour.SurfaceContainerLow() : theme?.Colour.Surface(),
        color: breakpointLg ? theme?.Colour.OnSurfaceVariant() : theme?.Colour.OnSecondaryContainer(),
        height: "100vh",
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        transition: "all 300ms cubic-bezier(0.2, 0, 0, 1)",
        width: breakpointLg ? "300px" : breakpointMd ? "88px" : "0px",
      }}
    >
      <NavContentLarge in={breakpointLg} />
      <NavContentMedium in={breakpointMd && !breakpointLg} />
    </nav>
  );
};

export default NavBar;
