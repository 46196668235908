import { useEasierTheme } from "3asier-theme";
import { Box, Button, Divider, Fade } from "@mui/material";
import MaterialIcon from "components/common/material-icon";
import NavListMediumOption from "../nav-list-medium-option";
import LogoCircle from "components/common/logo/LogoCircle";
import { useNavigate, useParams } from "react-router-dom";

const NavContentMedium = (props: { in: boolean }) => {
  const theme = useEasierTheme();

  const currentPage = useParams()["page"];
  const navigate = useNavigate();

  return (
    <Fade in={props.in} mountOnEnter unmountOnExit>
      <Box
        top={0}
        left={0}
        position={props.in ? "relative" : "absolute"}
        width={"88px"}
        display={"flex"}
        flexDirection={"column"}
        padding={"12px 0px"}
      >
        <NavListMediumOption
          onClick={() => {
            navigate("/");
          }}
          selected={!currentPage}
          text={"Home"}
          imgEl={<LogoCircle style={{ width: "24px", height: "24px", display: "block" }} />}
        />
        <Divider style={{ margin: "16px 16px", borderColor: theme?.Colour.OutlineVariant() }} />
        <NavListMediumOption
          onClick={() => {
            navigate("/dashboard");
          }}
          selected={currentPage === "dashboard"}
          text={"Dashboard"}
          icon={"space_dashboard"}
        />
        {false && (
          <NavListMediumOption
            onClick={() => {
              navigate("/visits");
            }}
            selected={currentPage === "visits"}
            text={"Visits"}
            icon={"note_stack"}
          />
        )}
        <NavListMediumOption
          onClick={() => {
            navigate("/events");
          }}
          selected={currentPage === "events"}
          text={"Events"}
          icon={"flowsheet"}
        />
      </Box>
    </Fade>
  );
};

export default NavContentMedium;
