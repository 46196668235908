import { useEasierTheme } from "3asier-theme";
import { Box, Typography } from "@mui/material";
import React from "react";

const NavListMediumOption = (props: {
  onClick: () => void;
  selected: boolean;
  icon?: string;
  imgEl?: JSX.Element;
  text: string;
}) => {
  const theme = useEasierTheme();

  const [hovered, setHovered] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);

  return (
    <Box
      style={{
        height: "60px",
        marginBottom: "12px",
        padding: "12px",
        width: "88px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
      }}
      onMouseDown={() => setClicked(true)}
      onMouseUp={() => setClicked(false)}
      onMouseMove={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
        setClicked(false);
      }}
      onClick={props.onClick}
    >
      <Box
        style={{
          position: "relative",
          overflow: "hidden",
          minHeight: "32px",
        }}
      >
        {props.imgEl ? (
          <Box
            style={{
              padding: "4px 12px",
              transform: `scale(${clicked ? 0.9 : hovered ? 1.1 : 1})`,
              transition: "all 300ms cubic-bezier(0.2, 0, 0, 1)",
              width: "fit-content",
              height: "fit-content",
            }}
          >
            {props.imgEl}
          </Box>
        ) : (
          <>
            <span
              style={{
                position: "absolute",
                background: props.selected
                  ? theme?.Colour.SecondaryContainer()
                  : hovered
                  ? theme?.Colour.SurfaceVariant()
                  : "transparent",
                borderRadius: "16px",
                width: props.selected || hovered ? "100%" : "32px",
                height: "100%",
                top: 0,
                left: "50%",
                transition: "width 300ms cubic-bezier(0.2, 0, 0, 1), background 300ms cubic-bezier(0.2, 0, 0, 1)",
                transform: "translateX(-50%)",
              }}
            ></span>
            <span
              style={{
                padding: "4px 12px",
                borderRadius: "16px",
                opacity: props.selected ? 0.8 : 0.625,
                fontVariationSettings: `"wght" ${clicked ? 300 : hovered ? 600 : 400}, "opsz" 24, "FILL" ${
                  props.selected ? 1 : 0
                }`,
                transition: "font-variation-settings 300ms cubic-bezier(0.2, 0, 0, 1)",
                color: props.selected
                  ? theme?.Colour.OnSecondaryContainer()
                  : hovered
                  ? theme?.Colour.OnSurfaceVariant()
                  : undefined,
              }}
              className={"material-symbols-outlined"}
            >
              {props.icon ?? "question_mark"}
            </span>
          </>
        )}
      </Box>
      <Typography
        variant={"caption"}
        style={{
          marginTop: "8px",
          transition: "font-variation-settings 300ms cubic-bezier(0.2, 0, 0, 1)",
          fontVariationSettings: `"GRAD" ${clicked ? -50 : props.selected ? 125 : hovered ? 50 : 0}`,
          color: props.selected ? theme?.Colour.OnSurface() : undefined,
        }}
      >
        {props.text}
      </Typography>
    </Box>
  );
};

export default NavListMediumOption;
