import { TokenPalette } from "3asier-theme";

const tokens: TokenPalette = {
  primary: {
    0: "#000000",
    10: "#00164f",
    20: "#072978",
    25: "#193584",
    30: "#274190",
    35: "#344d9c",
    40: "#415aa9",
    50: "#5b73c4",
    60: "#758de0",
    70: "#90a7fd",
    80: "#b6c4ff",
    90: "#dce1ff",
    95: "#eff0ff",
    98: "#faf8ff",
    99: "#fefbff",
    100: "#ffffff",
  },
  secondary: {
    0: "#000000",
    10: "#171b2c",
    20: "#2c2f42",
    25: "#373a4d",
    30: "#424659",
    35: "#4e5165",
    40: "#5a5d72",
    50: "#73768b",
    60: "#8d8fa6",
    70: "#a7aac1",
    80: "#c3c5dd",
    90: "#dfe1f9",
    95: "#f0efff",
    98: "#fbf8ff",
    99: "#fefbff",
    100: "#ffffff",
  },
  tertiary: {
    0: "#000000",
    10: "#3e001d",
    20: "#5e1133",
    25: "#6c1d3e",
    30: "#7b2949",
    35: "#893455",
    40: "#984061",
    50: "#b6587a",
    60: "#d57194",
    70: "#f48bae",
    80: "#ffb0c8",
    90: "#ffd9e2",
    95: "#ffecf0",
    98: "#fff8f8",
    99: "#fffbff",
    100: "#ffffff",
  },
  neutral: {
    0: "#000000",
    10: "#1b1b1f",
    20: "#303034",
    25: "#3b3b3f",
    30: "#47464a",
    35: "#525256",
    40: "#5f5e62",
    50: "#77767a",
    60: "#919094",
    70: "#acaaaf",
    80: "#c8c6ca",
    90: "#e4e1e6",
    95: "#f3f0f4",
    98: "#fbf8fd",
    99: "#fefbff",
    100: "#ffffff",
  },
  "neutral-variant": {
    0: "#000000",
    10: "#1a1b23",
    20: "#2f3038",
    25: "#3a3b43",
    30: "#45464f",
    35: "#51525b",
    40: "#5d5e67",
    50: "#767680",
    60: "#8f909a",
    70: "#aaaab4",
    80: "#c6c6d0",
    90: "#e2e1ec",
    95: "#f0f0fa",
    98: "#faf8ff",
    99: "#fefbff",
    100: "#ffffff",
  },
};

export default tokens;
