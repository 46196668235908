import { Box, Button, ButtonBase, IconButton, Popover, Typography } from "@mui/material";
import User from "models/user";
import React, { useEffect } from "react";
import AccountButton from "../account-button";
import { useEasierTheme } from "3asier-theme";
import MaterialIcon from "../material-icon";
import ProfileService from "services/profile-service";
import SiteDialog from "../site-dialog";
import Permission from "models/permission";

const AccountMenu = (props: { toggleTheme: () => void }) => {
  const theme = useEasierTheme();
  const [siteAnchor, setSiteAnchor] = React.useState<HTMLElement>();

  const [anchor, setAnchor] = React.useState<HTMLElement>();

  const [user, setUser] = React.useState<User>();

  const [permissions, setPermissions] = React.useState<Permission[]>([]);

  useEffect(() => {
    new ProfileService().get().then((user) => {
      setUser(user);
    });
  }, []);

  return (
    <>
      <AccountButton onClick={(el) => setAnchor(el)} user={user} />
      <Popover
        anchorEl={anchor}
        open={!!anchor}
        onClose={() => setAnchor(undefined)}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        slotProps={{
          paper: {
            elevation: 3,
            style: {
              borderRadius: "12px",
              background: theme?.Colour.SurfaceContainerHigh(),
            },
          },
        }}
      >
        <Box display={"flex"} padding={"12px 16px"} alignItems={"center"}>
          <Box
            width={"80px"}
            height={"80px"}
            borderRadius={"24px"}
            style={{ background: theme?.Colour.SurfaceContainer() }}
            display={"flex"}
          >
            <MaterialIcon style={{ margin: "auto", color: theme?.Colour.OnSurfaceVariant() }} icon={"person"} />
          </Box>
          <Box padding={"0px 12px"}>
            <Typography style={{ color: theme?.Colour.OnSurface(), fontVariationSettings: `"opsz" 14` }}>
              {user?.name}
            </Typography>
            <Typography variant={"body2"} style={{ color: theme?.Colour.OnSurfaceVariant() }}>
              {user?.email}
            </Typography>
          </Box>
        </Box>
        <Box display={"flex"} padding={"0px 16px 12px 16px"}>
          <IconButton size={"small"} style={{ marginLeft: "auto", marginRight: "4px" }} onClick={props.toggleTheme}>
            <MaterialIcon
              icon={theme?.mode === "dark" ? "light_mode" : "dark_mode"}
              style={{ color: theme?.Colour.OnSurface() }}
            />
          </IconButton>
          <IconButton size={"small"}>
            <MaterialIcon icon={"logout"} style={{ color: theme?.Colour.OnSurface() }} />
          </IconButton>
        </Box>
      </Popover>
    </>
  );
};

export default AccountMenu;
