import { useEasierTheme } from "3asier-theme";
import { Box, Typography } from "@mui/material";
import AccountMenu from "../account-menu";
import React, { useEffect } from "react";
import SiteSelector from "../site-dialog";

const HeaderBar = (props: {
  title: string;
  toggleTheme: () => void;
  onSiteChange: (site_id: string) => void;
  siteID?: string;
}) => {
  const theme = useEasierTheme();

  const [shrunk, setShrunk] = React.useState(false);

  useEffect(() => {
    const headerEl = document.getElementById(`header-bar`);

    window.onscroll = () => {
      setShrunk(window.scrollY > 48);
    };

    console.log(headerEl);
  }, []);

  return (
    <Box
      id={`header-bar`}
      width={shrunk ? "calc(min(1264px, 100%))" : "100%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      height={"88px"}
      padding={`0px 64px`}
      position={"sticky"}
      top={"0px"}
      style={{
        zIndex: 3,
        transition: "width 300ms cubic-bezier(0.2, 0, 0, 1)",
      }}
    >
      <Box
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "50%",
          background: theme?.Colour.Background(),
          zIndex: -1,
        }}
      ></Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        width={"100%"}
        padding={shrunk ? "16px 32px" : "0px"}
        bgcolor={shrunk ? theme?.Colour.SurfaceContainerLow() : undefined}
        borderRadius={"44px"}
        style={{
          transition: "all 300ms cubic-bezier(0.2, 0, 0, 1)",
        }}
      >
        <Box padding={"0px"}>
          <Typography
            variant={"h4"}
            style={{
              color: theme?.Colour.OnBackground(),
              letterSpacing: 0,
              fontVariationSettings: `"opsz" 32`,
            }}
          >
            {props.title}
          </Typography>
        </Box>
        <Box>
          <SiteSelector onSiteChange={props.onSiteChange} site_id={props.siteID} />
          <AccountMenu toggleTheme={props.toggleTheme} />
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderBar;
