import config from "config";
import AuthService from "./auth-service";

export default class RestClient {
  private readonly root = `${config.api.PROTOCOL}://${config.api.HOSTNAME}:${config.api.PORT}${config.api.PATH}`;

  private parseJson = (str: string) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  };

  protected readonly token = `Bearer ${AuthService.getToken()}`;
  protected readonly headers = {
    Authorization: this.token,
    "Content-Type": `application/json`,
  };

  protected buildURL = (path: string, ...query: string[] | [string, string | boolean | number | undefined][]) => {
    const args = query
      .map((x) => (typeof x === "string" ? x : x[1] !== undefined ? `${x[0]}=${encodeURIComponent(x[1])}` : ""))
      .filter((o) => !!o);
    return `${this.root}${path}${(args ?? []).length ? `?${args.join("&")}` : ""}`;
  };
}
